import React from "react";

function Footer() {
  return (
    <section className="relative w-full py-10 flex flex-col ">
      <video
        className="absolute inset-0 w-full h-full object-cover object-top"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src="/vid.mp4" type="video/mp4" />
        <source src="/vid.mp4" type="video/webm" />
        Your browser does not support the video tag.
      </video>

      <p className="relative mt-8 text-base leading-6 ml-10 z-10 text-white">
        All rights reserved - Jeffson Junior Celestin - 2024
      </p>

      <div className="absolute inset-0 bg-black opacity-50"></div>
    </section>
  );
}

export default Footer;
