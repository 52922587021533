import React from 'react';
import "../App.css";

const Gallery = () => {
  return (
    <div className="container mx-auto p-4 py-10 bg-black">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {Array.from({ length: 9 }).map((_, index) => (
          <div
            key={index}
            className="relative group overflow-hidden rounded-md shadow-lg cursor-pointer transition-all duration-300"
          >
            <img
              src={`pic${index+1}.jpeg`}
              alt={`Gallery ${index}`}
              className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
            />
            {/* <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-500">
              <span className="text-white text-lg font-semibold">View Image</span>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
