import React, { useState } from 'react';

const Dropdown = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b">
      <div
        className="flex justify-between items-center py-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-medium">{title}</h3>
        <span
          className={`transition-transform transform ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
        >
          ▼
        </span>
      </div>
      <div
        className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
          isOpen ? 'max-h-96' : 'max-h-0'
        }`}
      >
        <div className="py-4 text-gray-600">{content}</div>
      </div>
    </div>
  );
};

const DropdownContainer = () => {
  const dropdownItems = [
    {
      title: 'Oil & Gas Trading',
      content: `
        "My career in oil and gas began in Liberia, where I saw an opportunity to enter the energy market. 
        Since then, I have expanded my business operations globally, particularly in Venezuela and Dubai, 
        trading oil and gas at scale. With vast connections and deep industry knowledge, we ensure our clients 
        have access to top-tier energy resources."
        
        Key Highlights:
        - Operations spanning across Liberia, Venezuela, and Dubai.
        - Large-scale oil and gas trading with a global reach.
      `,
    },
    {
      title: 'Real Estate Investments',
      content: `
        "With a keen eye for market trends, I ventured into real estate and now own prime properties 
        in major cities such as New York, Dubai, and Paris. My portfolio includes luxury estates, 
        commercial properties, and urban developments that continue to expand."
        
        Key Investments:
        - Luxury estates in New York, Dubai, and Paris.
        - Urban development projects and commercial properties in high-value markets.
      `,
    },
    {
      title: 'Assou Collection (Luxury Clothing Line)',
      content: `
        Brand Overview:
        "Assou Collection was born from my passion for high fashion. My brand offers exclusive, 
        high-class apparel worn by presidents, kings, and celebrities worldwide."
        
        Design Philosophy:
        "At Assou Collection, we create timeless, elegant designs that cater to the sophisticated 
        tastes of global leaders and icons."
        
        - Timeless, elegant high-fashion apparel.
        - Worn by global leaders and influential celebrities.
        
        Call-to-Action: 
        <a href="/shop" class="text-blue-500 underline">Shop Assou Collection</a>
      `,
    },
    {
      title: 'Energy & Sustainability',
      content: `
        "My ventures focus on sustainability and renewable energy. We lead projects that support the 
        transition to greener energy solutions while maintaining a balance with our traditional oil 
        and gas operations. I believe in the future of energy, where sustainability plays a pivotal role."
        
        Sustainable Focus:
        - Renewable energy investments alongside traditional energy operations.
        - Commitment to ethical business practices in energy trading.
      `,
    },
    {
      title: 'Contact Us',
      content: `
        For inquiries about business ventures or partnerships, please reach out:
        Princenoir_3@hotmail.com"
      `,
    },
  ];
  

  return (
    <div className="max-w-lg mx-auto bg-white shadow-lg p-6 lg:pt-40">
      {dropdownItems.map((item, index) => (
        <Dropdown key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default DropdownContainer;
