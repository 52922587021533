import React from "react";
import "../App.css";

function Operations() {
  return (
    <div className="bg-black">
      <div className="lg:py-20 py-10 flex flex-col justify-center items-center">
        <div className="relative z-10 lg:w-[1240px] w-4/5 gap-8 py-10 flex-col flex justify-start items-center ">
          <div className="text-white grid md:grid-cols-2 lg:grid-cols-3 gap-12 mt-10">
            <a href="/" className="flex flex-col gap-2">
              <div className="w-full h-96 overflow-hidden">
                <img
                  src="./rock-breaker.jpg"
                  alt="service"
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="lg:text-xl font-bold">Calcium Carbonate Mining</p>
            </a>
            <a href="/" className="flex flex-col gap-2">
              <div className="w-full h-96 overflow-hidden">
                <img
                  src="./MCherga.jpeg"
                  alt="service"
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="lg:text-xl font-bold">Power Generation</p>
            </a>
            <a href="/" className="flex flex-col gap-2">
              <div className="w-full h-96 overflow-hidden">
                <img
                  src="./krane.jpg"
                  alt="service"
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="lg:text-xl font-bold">Oil & Gas Exploration & Production</p>
            </a>
            <a href="/" className="flex flex-col gap-2">
              <div className="w-full h-96 overflow-hidden">
                <img
                  src="./refining.jpg"
                  alt="service"
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="lg:text-xl font-bold">Oil Refining</p>
            </a>
            <a href="/" className="flex flex-col gap-2">
              <div className="w-full h-96 overflow-hidden">
                <img
                  src="./mining.jpg"
                  alt="service"
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="lg:text-xl font-bold">Iron & Tin Ore Mining & Supply</p>
            </a>
            <a href="/" className="flex flex-col gap-2">
              <div className="w-full h-96 overflow-hidden">
                <img
                  src="./ports.jpg"
                  alt="service"
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="lg:text-xl font-bold">Ports & Logistics</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Operations;
