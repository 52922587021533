import React from "react";
import "../App.css";
import DropdownContainer from "../components/DropDown";

function AboutUs() {
  return (
    <div className="bg-black">
      <div className="flex justify-center flex-col items-center py-20 text-white">
        <div className="relative z-10 lg:w-[1340px] w-4/5 gap-4 text-sm flex lg:flex-row flex-col justify-center items-start">
          <div className="lg:w-1/2 flex flex-col gap-10 justify-center items-center">
            <h2 className="lg:text-4xl text-2xl font-bold">
              From Humble Beginnings to Global Success – A Journey of Vision and
              Perseverance
            </h2>

            <div className="text-sm flex flex-col gap-4">
            <p>
  <span class="font-semibold">Jeffson Junior Celestin</span>{" "}
  was born on July 31, 1987, in Haiti, into a prominent family with roots in both politics and business. 
  Growing up, Jeffson was inspired by his family's involvement in shaping the country's future and the entrepreneurial ventures they managed.
   This environment instilled in him a strong sense of purpose and drive to not only uphold his family’s legacy but also to forge new paths 
   and opportunities for others. His early years nurtured values of leadership, ambition, and a commitment to making a positive impact
    on his community and beyond.
</p>


              <p>
                After finishing high school, Jeffson moved to Canada to pursue
                his studies in criminology at the prestigious{" "}
                <span className="font-semibold">University of Ottawa</span>. It
                was here that he realized the power of education and networking,
                opening doors to a broader vision of global opportunities. Armed
                with knowledge, Jeffson's journey took him to the United States,
                where he transitioned into the oil and gas industry,
                establishing a reputation for excellence and innovation.
              </p>

              <div className="flex justify-center my-6">
                <img
                  src="./two.jpeg"
                  alt="Jeffson's Journey"
                  className="shadow-lg w-full lg:w-3/4 object-cover"
                />
              </div>

              <p>
                Over the years, Jeffson expanded his ventures globally, working
                in countries such as Liberia, Dubai, and Venezuela. His network
                of high-level connections and deep understanding of the global
                trade landscape propelled him into leadership roles across
                various sectors. Today, Jeffson manages a diverse portfolio of
                businesses, including{" "}
                <span className="font-semibold">Assou Collection</span>, a
                luxury clothing line, oil and gas ventures spanning three
                continents, and a real estate portfolio that spans the globe.
              </p>
            </div>

            <h3 className="text-2xl font-bold lg:w-3/5 w-4/5 text-center">
              Empowering Industries Through Innovation and Resourcefulness
            </h3>

            <div>
              <p>
                Jeffson Junior Celestin's business acumen and leadership have
                resulted in a personal net worth exceeding $700M, with ongoing
                ventures in industries that include luxury fashion, energy, and
                real estate. His vision and adaptability continue to shape
                global markets, driving forward innovative projects that
                emphasize sustainability and long-term success.
              </p>
              <p>
                Beyond his own ventures, Jeffson remains dedicated to giving
                back. His businesses not only excel in profitability but also in
                ethical practices, maintaining a commitment to social
                responsibility and environmental stewardship.
              </p>
            </div>
          </div>

          <div className="lg:w-1/2 flex gap-14 lg:mt-0 mt-10 flex-col justify-center items-center">
            <img
              src="one.jpeg"
              alt="Jeffson Junior Celestin"
              className="lg:w-3/4"
            />
            <div className="flex flex-col gap-4">
              <p>
                In addition to leading ventures across multiple sectors,
                Jeffson's journey stands as a beacon of resilience and
                entrepreneurial success. His oil and gas projects across Haiti,
                Liberia, and Venezuela reflect his commitment to both technical
                excellence and responsible resource management.
              </p>
              <p>
                Under Jeffson’s leadership, his companies continue to embrace
                the challenges and opportunities of a rapidly evolving global
                economy. His journey from humble beginnings to global prominence
                is a testament to his vision, resilience, and passion for
                empowering industries worldwide.
              </p>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-gray-300 text-black tracking-[10px] lg:w-2/3 font-medium py-4 px-8 rounded focus:outline-none hover:bg-gray-400 transition duration-300"
              >
                Explore Our Projects
              </button>
            </div>
          </div>
        </div>

        <div className="relative z-10 lg:w-[1340px] w-4/5 gap-4 text-sm flex flex-col justify-start items-start">
          <div className="lg:w-3/4 mt-20">
            <h3 className="lg:text-3xl text-2xl font-bold">
              Jeffson Junior Celestin – A Visionary Leader
            </h3>
            <div className="flex flex-col gap-4 mt-12">
              <p>
                Jeffson Junior Celestin is a seasoned entrepreneur and the
                mastermind behind several global ventures, including the{" "}
                <span className="font-semibold">Assou Collection</span>, a
                luxury clothing line that reflects his impeccable taste and
                attention to detail. With a diversified portfolio that includes
                energy projects and high-end real estate developments across
                three continents, Jeffson has built a legacy of innovation and
                success.
              </p>
              <p>
                Throughout his career, Jeffson has been a driving force behind
                the expansion of his ventures, particularly in the oil and gas
                sectors. His experience spans decades of expertise in
                commodities, international trade, and resource management,
                forging connections that have significantly impacted his
                businesses' growth.
              </p>
              <p>
                Jeffson’s dedication to sustainability and ethical practices
                sets him apart as a leader in the global business community. His
                vision, combined with a deep respect for the environment and
                communities, has solidified his reputation as a forward-thinking
                entrepreneur. Today, his $700M+ net worth is a testament to his
                hard work, strategic insights, and unrelenting pursuit of
                excellence.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="relative w-full h-screen flex  justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url('/sky.jpg')` }}
      >
        <div className="relative z-10 lg:w-[1340px] w-4/5 lg:gap-0 gap-4 flex lg:flex-row flex-col">
          <h2 className="lg:text-[4.2rem] text-3xl lg:leading-[60px] font-bold lg:w-1/2">
            Take a minute to See what we're passionate about . . .
          </h2>
          <div className="lg:w-1/2">
            <DropdownContainer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
