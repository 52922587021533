import React from "react";
import "../App.css";

function HomePage() {
  const textArray = Array(10).fill("Jeffson Junior Celestin");
  return (
    <div>
      <div>
        <div className="slider-container">
          <div className="relative w-full h-[70vh] lg:-mb-[60vh] -mb-[80vh] overflow-hidden">
            <div className="absolute w-full h-full overflow-hidden whitespace-nowrap uppercase">
              <div className="inline-block animate-marquee">
                {textArray.map((text, index) => (
                  <h1
                    key={index}
                    className="font-bold lg:text-[26.2rem] text-[12rem] inline-block"
                  >
                    {text}&nbsp;
                  </h1>
                ))}
              </div>
            </div>
          </div>

          <div
            className="relative w-full h-[200vh] flex flex-col justify-center items-center bg-cover bg-center"
            style={{ backgroundImage: `url('/Mountain.png')` }}
          >
            <div className="flex justify-center gap-20 lg-mt-20 mt-10 text-white items-center flex-col">
              <a
                href={"/"}
                className="lg:px-8 px-4 py-4 bg-black border-2 hover:opacity-80 tracking-[10px] border-white rounded-tl-xl rounded-br-xl flex justify-center items-center lg:text-xl text-sm text-balance text-center font-medium"
              >
                Assou Collection
              </a>
              <a
                href={"/"}
                className="lg:px-8 px-4 py-4 bg-black border-2 hover:opacity-80 tracking-[10px] border-white rounded-tl-xl rounded-br-xl flex justify-center items-center lg:text-xl text-sm text-balance text-center font-medium"
              >
                Oil & Gas Ventures
              </a>
              <a
                href={"/"}
                className="lg:px-8 px-4 py-4 bg-black border-2 hover:opacity-80 tracking-[10px] border-white rounded-tl-xl rounded-br-xl flex justify-center items-center lg:text-xl text-sm text-balance text-center font-medium"
              >
                Global Real Estate 
              </a>
            </div>
          </div>
          <div className="flex flex-col justify-center bg-black items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="100 0 500 100"
              preserveAspectRatio="#0070C0"
              className=" transform scale-x-[-1]"
            >
              <path
                class=" fill-[#2c2c2c]"
                d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
              ></path>
            </svg>
            <div className="lg:w-[800px] w-4/5 flex mb-20 justify-center items-start">
              <form className="w-full flex flex-col gap-4 p-6 rounded-lg">
                <div className="mb-4">
                  <label
                    className="block text-white text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <div className="flex -mx-2">
                    <div className="w-1/2 px-2">
                      <label
                        className="block text-gray-400 text-xs mb-2"
                        htmlFor="first-name"
                      >
                        First Name
                      </label>
                      <input
                        className="appearance-none border border-gray-300 w-full py-2 px-3 text-black leading-tight focus:outline-none focus:ring focus:ring-white"
                        id="first-name"
                        type="text"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="w-1/2 px-2">
                      <label
                        className="block text-gray-400 text-xs mb-2"
                        htmlFor="last-name"
                      >
                        Last Name
                      </label>
                      <input
                        className="appearance-none border border-gray-300 w-full py-2 px-3 text-black leading-tight focus:outline-none focus:ring focus:ring-white"
                        id="last-name"
                        type="text"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    className="block text-white text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email <span className="text-gray-400">(required)</span>
                  </label>
                  <input
                    className="appearance-none border border-gray-300 w-full py-2 px-3 text-black leading-tight focus:outline-none focus:ring focus:ring-white"
                    id="email"
                    type="email"
                    placeholder="Email"
                    required
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-white text-sm font-bold mb-2"
                    htmlFor="phone"
                  >
                    Phone
                  </label>
                  <input
                    className="appearance-none border border-gray-300 w-full py-2 px-3 text-black leading-tight focus:outline-none focus:ring focus:ring-white"
                    id="phone"
                    type="tel"
                    placeholder="Phone"
                  />
                </div>

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="bg-gray-300 text-black font-bold py-4 px-8 rounded focus:outline-none hover:bg-gray-400 transition duration-300"
                  >
                    S u b m i t
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
