import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleSlideMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="flex justify-center items-center header z-50  bg-black">
        <div className="flex items-center w-[1350px] text-white lg:h-[240px] h-[180px] justify-between lg:justify-start lg:mx-auto mx-4">
          <Link to="/" className=" md:w-1/5 w-1/2">
            <img
              href="/"
              src="./logo2.jpeg"
              alt="nikivya"
              className="w-1/2 cursor-pointer rounded-full"
            />
          </Link>
          <div className="lg:flex w-1/2 hidden items-center gap-6 justify-start">
            <Link
              to="/"
              className="cursor-pointer group flex py-2 flex-col justify-center items-center hover:text-[var(--secondary-color)] duration-300 ease-in-out transform transition-all"
            >
              Home
              <span className="absolute bottom-1 h-[1.5px] bg-white w-8 scale-x-0 group-hover:scale-x-100 origin-center transition-transform duration-300 ease-linear"></span>
            </Link>

            <Link
              to="/AboutUs"
              className="cursor-pointer group flex py-2 flex-col justify-center items-center hover:text-[var(--secondary-color)] duration-300 ease-in-out transform transition-all"
            >
              About us
              <span className="absolute bottom-1 h-[1.5px] bg-white w-8 scale-x-0 group-hover:scale-x-100 origin-center transition-transform duration-300 ease-linear"></span>
            </Link>
            <Link
              to="/Gallery"
              className="cursor-pointer group flex py-2 flex-col justify-center items-center hover:text-[var(--secondary-color)] duration-300 ease-in-out transform transition-all"
            >
              Gallery
              <span className="absolute bottom-1 h-[1.5px] bg-white w-8 scale-x-0 group-hover:scale-x-100 origin-center transition-transform duration-300 ease-linear"></span>
            </Link>
            <Link
              to="/Operations"
              className="cursor-pointer group flex py-2 flex-col justify-center items-center hover:text-[var(--secondary-color)] duration-300 ease-in-out transform transition-all"
            >
              Operations
              <span className="absolute bottom-1 h-[1.5px] bg-white w-8 scale-x-0 group-hover:scale-x-100 origin-center transition-transform duration-300 ease-linear"></span>
            </Link>
            <Link
              to="/ContactUs"
              className="cursor-pointer group flex py-2 flex-col justify-center items-center hover:text-[var(--secondary-color)] duration-300 ease-in-out transform transition-all"
            >
              Contact us
              <span className="absolute bottom-1 h-[1.5px] bg-white w-8 scale-x-0 group-hover:scale-x-100 origin-center transition-transform duration-300 ease-linear"></span>
            </Link>
          </div>
          <button
            className={`lg:hidden text-black focus:outline-none`}
            onClick={toggleSlideMenu}
          >
            <svg
              className={`h-10 w-10 fill-white stroke-white `}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <div
          className={`overlay-four bg-black ${
            isMenuOpen ? "slide-in" : "slide-out"
          } lg:hidden`}
        >
          <div className="w-full text-white  flex justify-center mt-20 items-center flex-col">
            <div className=" font-bold text-[2rem] mb-5">Menu</div>

            <div className="flex flex-col items-start gap-4 justify-start">
              <Link
                onClick={toggleSlideMenu}
                to="/"
                className="cursor-pointer"
              >
                Home
              </Link>
              <Link onClick={toggleSlideMenu} to="/AboutUs">
                About us
              </Link>
              <Link onClick={toggleSlideMenu} to="/Gallery">
              Gallery
              </Link>
              <Link
                onClick={toggleSlideMenu}
                to="/Operations"
                className="cursor-pointer"
              >
                Operations
              </Link>
              <Link onClick={toggleSlideMenu} to="/ContactUs">
                Contact us
              </Link>
            </div>
          </div>
          <svg
              fill="#ffffff"
              onClick={toggleSlideMenu}
              width="24px"
              height="24px"
              viewBox="0 0 16 16"
              className="absolute m-10 right-0"
              xmlns="http://www.w3.org/2000/svg"
            >
              
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
                  fill-rule="evenodd"
                ></path>{" "}
              </g>
            </svg>
        </div>
      )}
    </>
  );
};

export default Header;
