import React from "react";
import "../App.css";

function ContactUs() {
  return (
    <div className="flex justify-center items-center bg-black">
      <div className="lg:w-[1340px] text-white w-4/5 lg:flex-row flex-col gap-8 lg:py-20 py-10 flex justify-start  ">
        <div className="lg:w-1/2 flex justify-center items-center">
        <div className="lg:w-3/4 flex flex-col gap-8">
          <h2 className="lg:text-7xl font-bold text-4xl">Contact us</h2>
          <p>
            Whether you have a mineral supply order that needs to be filled, are
            looking for a mining consultant & operator, or are actively seeking
            investment opportunities in domestic or international Oil, Energy or
            sustainability, please don't hesitate to reach out.
          </p>
        </div>
          
        </div>
        <div className="lg:w-1/2">
          <form className="w-full flex flex-col gap-4 lg:p-6 rounded-lg">
            <div className="mb-4">
              <label
                className="block text-white text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <div className="flex -mx-2">
                <div className="w-1/2 px-2">
                  <label
                    className="block text-gray-400 text-xs mb-2"
                    htmlFor="first-name"
                  >
                    First Name
                  </label>
                  <input
                    className="appearance-none border border-gray-300 w-full py-2 px-3 text-black leading-tight focus:outline-none focus:ring focus:ring-white"
                    id="first-name"
                    type="text"
                    placeholder="First Name"
                  />
                </div>
                <div className="w-1/2 px-2">
                  <label
                    className="block text-gray-400 text-xs mb-2"
                    htmlFor="last-name"
                  >
                    Last Name
                  </label>
                  <input
                    className="appearance-none border border-gray-300 w-full py-2 px-3 text-black leading-tight focus:outline-none focus:ring focus:ring-white"
                    id="last-name"
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label
                className="block text-white text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email <span className="text-gray-400">(required)</span>
              </label>
              <input
                className="appearance-none border border-gray-300 w-full py-2 px-3 text-black leading-tight focus:outline-none focus:ring focus:ring-white"
                id="email"
                type="email"
                placeholder="Email"
                required
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-white text-sm font-bold mb-2"
                htmlFor="phone"
              >
                Phone
              </label>
              <input
                className="appearance-none border border-gray-300 w-full py-2 px-3 text-black leading-tight focus:outline-none focus:ring focus:ring-white"
                id="phone"
                type="tel"
                placeholder="Phone"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-white text-sm font-bold mb-2"
                htmlFor="Message"
              >
                Message <span className="text-gray-400">(required)</span>
              </label>
              <textarea
                className="appearance-none border border-gray-300 w-full py-2 px-3 text-black leading-tight focus:outline-none focus:ring focus:ring-white"
                type="text"
                placeholder="write here"
              />
            </div>

            <div className="flex justify-start">
              <button
                type="submit"
                className="bg-gray-300 text-black font-bold py-4 px-8 rounded focus:outline-none hover:bg-gray-400 transition duration-300"
              >
                S e n d
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
