import React from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import "./App.css"
import HomePage from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Operations from "./pages/Operations";
import Gallery from "./pages/Gallery";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Operations" element={<Operations />} />
        <Route path="/ContactUs" element={< ContactUs/>} />
        <Route path="/Gallery" element={<Gallery />} />
      </Routes>
    </Layout>
  );
}

export default App;
